import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {faqmsg} from '../../constants/messages';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Grid, Modal, Row, PanelGroup, Panel } from "react-bootstrap";
import './faq.css';
import { faAngleDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const FaqModal = (props) => {
    const [styling, setStyling] = useState('');
    const defaultBackground = "var(--color-red)";

    return (
        <Modal
            {...props}
            size="lg"
            dialogClassName="full_screen faq_modal"
            animation={false}
        >
            <Modal.Header style={{background: styling.background ? styling.background : defaultBackground}}>
                <Grid>
                    <Row>
                        <Col md={12}>
                            <span className="navbar-text">{props.intl.formatMessage(faqmsg.modal_header_b)}</span>
                            <a className={"close"} onClick={props.onHide}>
                                <FontAwesomeIcon icon={ [ 'fa', 'times-circle' ] }/>
                            </a>
                        </Col>
                    </Row>
                </Grid>

            </Modal.Header>
            <Modal.Body>
                <Grid>
                    <Row>
                        <Col xs={12} md={9} lg={6}>
                            <PanelGroup accordion id="accordion-example">
                                <Panel eventKey="1">
                                    <Panel.Heading>
                                        <Panel.Title toggle>{props.intl.formatMessage(faqmsg.modal_body_panel_1_heading_title)}<span className='pull-right'><FontAwesomeIcon icon={ faAngleDown }/></span></Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        {props.intl.formatMessage(faqmsg.modal_body_panel_1_body)}
                                    </Panel.Body>
                                </Panel>
                                <Panel eventKey="2">
                                    <Panel.Heading>
                                        <Panel.Title toggle>{props.intl.formatMessage(faqmsg.modal_body_panel_2_heading_title)}<span className='pull-right'><FontAwesomeIcon icon={ faAngleDown }/></span></Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        {props.intl.formatMessage(faqmsg.modal_body_panel_2_body)}
                                    </Panel.Body>
                                </Panel>
                                <Panel eventKey="3">
                                    <Panel.Heading>
                                        <Panel.Title toggle>{props.intl.formatMessage(faqmsg.modal_body_panel_3_heading_title)}<span className='pull-right'><FontAwesomeIcon icon={ faAngleDown }/></span></Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        {props.intl.formatMessage(faqmsg.modal_body_panel_3_body)}
                                    </Panel.Body>
                                </Panel>
                            </PanelGroup>
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
        </Modal>
    );
}

const Faq = (props) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <a className="navbar-link navbar-right navbar-help" onClick={() => setModalShow(true)}>
                <FontAwesomeIcon icon={ [ 'fa', 'question-circle' ] }/>
            </a>
            <FaqModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                intl={props.intl}
            />
        </>
    );
};

export default injectIntl(Faq);
