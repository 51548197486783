import React, { useState, useContext, useEffect } from "react";
import {injectIntl} from "react-intl";
import { Col, Grid, Modal, Row, Button, InputGroup, Alert, Table } from "react-bootstrap";
import {swishPaymentModalmsg} from '../../constants/messages';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import images from '../../constants/images';
import './swishpayment.css';
import { JobContext } from "../../context/job-context";
import { UserContext } from "../../context/user-context";
import { ModalContext } from "../NotifyModal/modalContext";
import { parseDate } from "../../services/helpers";
import Moment from "moment";

const SwishPaymentModal = (props) => {
    const [styling, setStyling] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(false);
    const [paymentProgressState, setPaymentProgressState] = useState('');
    const [cancelMsg, setCancelMsg] = useState('');
    const defaultBackground = "var(--color-red)";
    const [phoneInput, setPhoneInput] = useState('');
    const countryCodes = [
        { code: '45', countryshort: 'dk', countryname: 'denmark', flag: images.flagdenmark },
        { code: '46', country: 'se', countryname: 'sweden', flag: images.flagsweden },
        { code: '47', country: 'no', countryname: 'norway', flag: images.flagnorway },
        { code: '354', country: 'is', countryname: 'iceland', flag: images.flagiceland },
        { code: '358', country: 'fi', countryname: 'finland', flag: images.flagfinland },
    ];
    const [countryCode, setCountryCode] = useState(countryCodes[1].code);
    const [countryFlag, setCountryFlag] = useState(countryCodes[1].flag);
    const [countryname, setCountryName] = useState(countryCodes[1].countryname);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setPhone(countryCode + phoneInput);
    }, [countryCode])

    const validatePhoneNbr = (input) => {
        setPhoneInput(input);
        input = countryCode + input; // use chosen country code 
        let re = /^\(?(\d{2})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        setPhone(input);
        if (re.test(input)) {
            setPhoneValid(true);
        } else {
            setPhoneValid(false);
        }
    }

    /**
     * Get makePayment function from JobContext.
     * The makePayment function is used to create payment from backend.
     */
    const { makePayment, paymentProgress, fetchJob } = useContext(JobContext);

    /**
     * Get getToken function from JobContext.
     * The getToken function is used to get authorization token to authorize backend request.
     */
    const { getToken } = useContext(UserContext);

    /**
     * Get handleModal function from ModalContext
     * The handleModal is used to show modal.
     */
    const { handleModal } = useContext(ModalContext);

    const SuccessMessageMessage = () => (

        <div>
            <h4>
                <span>{ props.intl.formatMessage(swishPaymentModalmsg.paid_with_swish) } { Moment().format("YYYY-MM-DD").toString() }.</span>
            </h4>
        </div>
    );

    const SuccessMessageContent = () => (
        <Row>
            <Col md={12}>
                <Table className="success-table">
                    <tbody>
                    <tr className="success">
                        <td>{ props.intl.formatMessage(swishPaymentModalmsg.amount_inc_taxes) }</td>
                        <td align="right"><strong>{props.invoice.openamount} {props.invoice.currency}</strong></td>
                    </tr>
                    <tr className="success">
                        <td>{ props.intl.formatMessage(swishPaymentModalmsg.invoice_ocr) }</td>
                        <td align="right"><strong>{ props.invoice.billnum }</strong></td>
                    </tr>
                    <tr className="success">
                        <td>{ props.intl.formatMessage(swishPaymentModalmsg.due_date) }</td>
                        <td align="right"><strong>{ props.invoice.paydate }</strong></td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    )

    const SuccessMessageHeader = () => (
        <div>
            <h4 className="text-success">{ props.intl.formatMessage(swishPaymentModalmsg.paid) }:</h4>
            <h3>{ props.intl.formatMessage(swishPaymentModalmsg.invoice) } { props.invoice.billnum }</h3>
        </div>
    );

    const submit = () => {
        // Change paymentprogress
        setPaymentProgressState('notpaid');

        // actual submition using submitNewDuedate
        makePayment(
            props.invoice.dpid,
            "swish",
            getToken(),
            phone
        )
        .then((res) => {
            progress(res.data.transactionid);
        })
        .catch((error) => {
            setCancelMsg('Something got wrong. Please try again.');
            setPaymentProgressState("cancelled");

            if (error.response && typeof error.response.data.Message !== "undefined") {
                // Swish errorcodes
                if (error.response.data.Message) {
                    switch (error.response.data.Message) {
                        case "ACMT03":
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error_ACMT03));
                            break;
                        case "PA02":
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error_PA02));
                            break;
                        case "VR01":
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error_VR01));
                            break;
                        case "VR02":
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error_VR02));
                            break;
                        case "BE18":
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error_BE18));
                            break;
                        default:
                            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error));
                    }
                }
            }
        });
    };

    /**
     * Check if payment is authorized
     * @param transactionId
     */
    const progress = (transactionId) => {
        paymentProgress(
            transactionId,
            "swish",
            getToken(),
            props.invoice.dpid
        ).then((res) => {
            if (typeof res.data.status !== "undefined") {
                setPaymentProgressState(res.data.status);
                if (res.data.status === "notpaid") {
                    // Try again after 5 second
                    setTimeout(function() {
                        progress(transactionId);
                    }, 5000);
                }
                if (res.data.status === "pending") {
                    handleModal({
                        message: <SuccessMessageMessage />,
                        content: <SuccessMessageContent />,
                        header: <SuccessMessageHeader />,
                        closeButtonTxt: props.intl.formatMessage(swishPaymentModalmsg.close)
                    });
                    // Hide payment modal
                    props.onHide();
                    fetchJob(props.invoice.dpid, getToken());
                }
            } else {
                setPaymentProgressState("cancelled");
            }
        })
        .catch((err) => {
            setPaymentProgressState("cancelled");
            setCancelMsg(props.intl.formatMessage(swishPaymentModalmsg.error));
        });
    }

    const handleDropdown = () => {
        setOpen(!open);
    };

    const countryCodeOptions = (countryCodes.length > 0 ? countryCodes.map((option, i) => {
        return (
            <>
                <li className={countryCode === option.code ? "bg-grey" : "menu-item"} key={option.code}>
                    <button onClick={() => handleCountry(option.code, option.flag, option.countryname)}>
                        <img className="flag" src={option.flag} alt={"flag-of-" + option.countryname} /> (+{option.code})
                    </button>
                </li>
            </>
        )
    }) : (<></>));

    function handleCountry(code, flag, name) {
        setCountryCode(code);
        setCountryFlag(flag);
        setCountryName(name);
        setOpen(!open);
    }

    
    return (
        <Modal
            {...props}
            size="lg"
            dialogClassName="full_screen swish_modal"
            animation={false}
        >
            <Modal.Header style={{background: styling.background ? styling.background : defaultBackground}}>
                <Grid>
                    <Row>
                        <Col md={12}>
                            <img className="navbar-brand" src={images.guardhome} alt="company"/>
                            <a className={"close"} onClick={props.onHide}><FontAwesomeIcon icon={ [ 'fa', 'times-circle' ] }/></a>
                        </Col>
                    </Row>
                </Grid>
            </Modal.Header>
            <Modal.Body>
                <Grid>
                    <Row>
                        <Col xs={12} md={9} lg={6}>
                            <Button
                                onClick={props.onHide}
                                color="black"
                                bsSize="large"
                                block
                                className='btn-primary-outline font-size-12 text-bold'
                            >
                                <span className='pull-left'><FontAwesomeIcon icon={faAngleLeft}/> {props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_1_col_button)}</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={9} lg={6}>
                            <h3>{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_2_col_h3)} {props.invoice.openamount} {props.invoice.currency}</h3>
                        </Col>
                    </Row>
                    <Row className="mt-8">
                        <Col xs={12} md={9} lg={6}>
                            <ul className={"list-unstyled"}>
                                <li><span className="font-size-12 color-light-gray">{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_3_col_li_1)}</span></li>
                                <li><span className="font-size-16">{props.invoice.creditor}</span></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={3} lg={2}>
                            <ul className={"list-unstyled"}>
                                <li><span className="font-size-12 color-light-gray">{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_4_col_1_li_1)}</span></li>
                                <li><span className="font-size-16">{props.invoice.billdate}</span></li>
                            </ul>
                        </Col>
                        <Col xs={4} md={3} lg={2} />
                        <Col xs={4} md={3} lg={2}>
                            <ul className={"list-unstyled"}>
                                <li><span className="font-size-12 color-light-gray">{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_4_col_3_li_1)}</span></li>
                                <li><span className="font-size-16">{props.invoice.billnum}</span></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mt-75">
                        <Col xs={12} md={9} lg={6} className={"text-center"}>
                            <img src={images.swishiconlg} alt="swish-icon-lg"/>
                        </Col>
                    </Row>
                    <Row className="mt-27">
                        <Col xs={12} md={9} lg={6}>
                            <label><span className="font-size-12 text-bold">{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_6_col_label)}</span></label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={9} lg={6}>
                            <InputGroup>
                                <span class="input-group-addon flag-container" id="swish-phone-area">
                                     <div className="dropdown">
                                        <button className="button" id="country-code" onClick={handleDropdown}> <img className="flag" src={countryFlag} alt={"flag-of-" + countryname} /> (+{countryCode}) <FontAwesomeIcon icon={faAngleDown} /></button>
                                        {open && (
                                            <ul className="menu">
                                                {countryCodeOptions}
                                            </ul>
                                        )}
                                    </div> 
                                </span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="1234567"
                                    aria-describedby="swish-phone-number"
                                    onChange={(e) => validatePhoneNbr(e.target.value)}
                                />
                                <span class="input-group-addon check-container" id="swish-phone-validate">
                                    { phoneValid ? (
                                        <img className="check" src={images.iconcheck} alt="valid"/>
                                    ) : ('') }
                                </span>
                            </InputGroup>
                        </Col>
                    </Row>
                    { paymentProgressState === '' || paymentProgressState === 'cancelled' ? (
                        <Row className="mt-24">
                            <Col xs={12} md={9} lg={6}>
                                <Button onClick={() => {submit()}} className='btn-block color-white bg-darkest font-size-16 text-bold'>
                                    <span>{props.intl.formatMessage(swishPaymentModalmsg.modal_body_row_8_col_button)} {props.invoice.openamount} {props.invoice.currency}</span>
                                </Button>
                            </Col>
                        </Row>
                    ) : ('') }
                    { paymentProgressState === 'cancelled' ? (
                        <Row className="mt-24">
                            <Col xs={12} md={9} lg={6}>
                                <Alert bsStyle="warning">
                                    { cancelMsg }
                                </Alert>
                            </Col>
                        </Row>
                    ) : ('') }
                    { paymentProgressState === 'notpaid' ? (
                        <Row className="mt-24">
                            <Col xs={5} md={3} lg={2}>
                                <div className="loader"/>
                            </Col>
                            <Col xs={7} md={6} lg={4}>
                                <h5>{props.intl.formatMessage(swishPaymentModalmsg.waiting_payment)}</h5>
                            </Col>
                        </Row>
                    ) : ('')}
                </Grid>
            </Modal.Body>
        </Modal>
    );
}

const SwishPayment = (props) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Button onClick={() => setModalShow(!modalShow)} className='btn-block text-aling-start bg-light-gray font-size-12 text-bold'>
                <span className='pull-left'>Swish</span>
                <span className='pull-right'><img className="swish-icon" src={images.swishicon} alt="swish-icon"/></span>
            </Button>
            <SwishPaymentModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                intl={props.intl}
                invoice={props.invoice}
            />
        </>
    );
}

export default injectIntl(SwishPayment);