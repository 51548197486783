import React, {createContext, useState} from "react";
import axios from "axios";

/**
 * Create JobContext
 */
const JobContext = createContext();

/**
 * Provides Job context to child components.
 * All things that can be considered as a some CRUD operation to Job should be handled in this context!
 *
 * @param {object} props
 * @returns
 */
const Job = (props) => {
    const [job, setJob] = useState('');

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    /**
     * Function to fetch Job from backend
     *
     * @param {string} query
     * @param {string} token
     * @returns
     */
    const fetchJob = (query, token) => {

        let _query = '';

        if(typeof query !== "undefined" && query) {
            _query = "/" + query;
        }

        headers["Authorization"] = "Bearer " + token;

        return new Promise((resolve, reject) => {
            axios({
                method: "GET",
                url: process.env.REACT_APP_API_URL + "/online/jobsearch" + _query,
                headers: headers
            })
            .then((res) => {
                // set fetched job
                setJob(res.data.job[0]);

                // resolve promise
                resolve(res.data.job[0]);
            })
            .catch((err) => {
                // reject promise
                reject(err);
            })
        });
    }

    /**
     * Function to submit new due date to backend
     *
     * @param {string} dpid
     * @param {string} newDuedate
     * @param {string} token
     * @returns
     */
    const submitNewDuedate = (dpid, newDuedate, token) => {

        headers["Authorization"] = "Bearer " + token;

		return new Promise((resolve, reject) => {

			axios({
				method: 'PATCH',
				url: process.env.REACT_APP_API_URL + '/online',
				headers: headers,
				data: JSON.stringify({
					id: dpid,
					dataset: {
						paydate: newDuedate
					}
				})
			})
			.then(() => {
				// if success, fetch job with other promise. If the promise fails it's execption is catched in the catch block.
				fetchJob(dpid, token);
			})
			.then((res) => {
				// if job was fetched ok resolve?
				resolve(null, res);
			})
			.catch((err) => {
				reject(err);
			});
		});
    }

    const getJob = () => {
        return job;
    }

    /**
     * Function to make payment
     * @param {string} dpid
     * @param {string} service
     * @param {string} token
     * @returns
     */
    const makePayment = (dpid, service, token, phone) => {
        headers["Authorization"] = "Bearer " + token;
        let url = process.env.REACT_APP_API_URL + '/online/registerpayment/' + dpid + '?service=' + service;
        if (service === "swish") {
            let callback = process.env.REACT_APP_API_URL + '/online/swishcallback';
            url = url +  '&phone=' + phone + '&callback=' + callback;
        }

        return new Promise((resolve, reject) => {

            axios({
                method: 'GET',
                url: url,
                headers: headers,
                responseType: 'json'
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Function to check paymentstatus
     * @param {string} transactionId
     * @param {string} service
     * @param {string} token
     * @returns
     */
    const paymentStatus = (transactionId, service, token) => {
        headers["Authorization"] = "Bearer " + token;
        let url = process.env.REACT_APP_API_URL + '/online/paymentstatus/' + transactionId + '?service=' + service;

        return new Promise((resolve, reject) => {

            axios({
                method: 'GET',
                url: url,
                headers: headers,
                responseType: 'json'
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * Function to check payment progress
     * @param {string} transactionId
     * @param {string} service
     * @param {string} token
     * @param {string} dpid
     * @returns
     */
    const paymentProgress = (transactionId, service, token, dpid) => {
        headers["Authorization"] = "Bearer " + token;
        let url = process.env.REACT_APP_API_URL + '/online/paymentprogress/' + transactionId + '?service=' + service;

        return new Promise((resolve, reject) => {

            axios({
                method: 'GET',
                url: url,
                headers: headers,
                responseType: 'json'
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    return (
        <JobContext.Provider value={{ fetchJob, getJob, submitNewDuedate, makePayment, paymentStatus, paymentProgress, job }}>
            {props.children}
        </JobContext.Provider>
    )

}

export {Job, JobContext}