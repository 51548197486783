import React, { useState } from 'react';
import './paymentInstallments.css';

const PaymentInstallments = (props) => {
    const [selected, setSelected] = useState(2)
    const [hover, setHover] = useState(null)
    return (
        <div>
            {props.installments.map((item, index) => {
                return (
                    <span className='mr-16'>
                    <button 
                        className={'paymentInstallments-button ' + (selected === index ? "bg-red" : "bg-white")}
                        id={index}
                        key={index.toString()} 
                        onClick={() => setSelected(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(null)} 
                    >
                        {item}
                    </button>
                    </span>);
            })}
            {props.infoText}
        </div>
    )
}

export default PaymentInstallments