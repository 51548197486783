import React from 'react';
import {injectIntl} from "react-intl";
import {headermsg} from '../../constants/messages'
import './header.css';

// Component that gathers things that are aligned as a header just below to the Navbar.
const Header = (props) => {
  return (
    <>
        <h5>
            {props.intl.formatMessage(headermsg.title)} {/*Att Betala:*/}
            { props.openAmount > 0 ? (
                ''
            ) : (
                ' ' + props.intl.formatMessage(headermsg.ispaid)
            ) }
        </h5>
        <h3>{props.intl.formatMessage(headermsg.invoiceNumber) + ' ' + props.invoiceNumber}</h3> {/*Faktura 284931*/}
    </>
  );
};

export default injectIntl(Header);
