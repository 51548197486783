import React from 'react';
import {injectIntl} from "react-intl";
import {paymentoptionsmsg} from '../../constants/messages'
import './paymentOptionsTitle.css'

// Payment option title component 
const PaymentOptionsTitle = (props) => {
  return (
    <>
        <h4>
          {props.intl.formatMessage(paymentoptionsmsg.title)}
        </h4>
    </>
  );
};

export default injectIntl(PaymentOptionsTitle);
