import React, {useState} from 'react';
import {Navbar, Grid, Row, Col} from 'react-bootstrap'
// TODO: invoicedata is left here for example to show how the data should be formed, so it can be used to populate footer.
// import invoicedata from '../../constants/data';
import {injectIntl} from "react-intl";
import './footer.css';

const Footer = (props) => {
    const [logoLoaded, setLogoLoaded] = useState(false);

    return (
        <Navbar className="navbar navbar-fixed-bottom footer" style={{background: props.style ? props.style['background-color'] : "var(--color-footer)"}}>
            <Row className=''>
                <Col xs={12} md={3} lg={2}>
                    {props.logo && 
                        <img
                        className={logoLoaded ? 'navbar-brand' : 'hidden'}
                        onLoad={() => setLogoLoaded(true)}   
                        alt="Brand"
                        src={props.logo}/>                       
                    }
                </Col>
            </Row>
        </Navbar>
    );
};

export default injectIntl(Footer);
