import React, {useState,Fragment, useEffect} from 'react';
import {Button, Modal, Carousel, CarouselItem, Grid, Row, Col} from "react-bootstrap";
import StepWizard from "react-step-wizard";
import Calendar from 'react-calendar'
import PaymentInstallments from './paymentInstallments';
import {injectIntl} from "react-intl";
import {paymentPlanmsg} from '../../constants/messages';
import 'react-calendar/dist/Calendar.css';
import './paymentPlan.css';
import transitions from './transitions.less';
import { toast } from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';


const PaymentPlanModal = (props) => {
    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        }
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (stats) => {
        /**TODO update state */
    };

    return (
      <Modal
        {...props}
        size="lg"
        dialogClassName="full_screen payment_plan_modal"
        animation={false}

      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <StepWizard
                onStepChange={onStepChange}
                transitions={state.transitions} // comment out for default transitions
            >
                <PaymentPlanStep1 hashKey={'FirstStep'} update={updateForm} onHide={props.onHide} intl={props.intl} />
                <PaymentPlanStep2 form={state.form} onHide={props.onHide} intl={props.intl}/>
            </StepWizard>
        </Modal.Body>
      </Modal>
    );
}

/** Steps */
const PaymentPlanStep1 = props => {
 return (
            <Grid>
                <Row className="mt-75">
                    <Col xs={6} md={4}>
                        <h3>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_1_h3)}</h3>
                    </Col>
                </Row>
                <Row className="mt-27">
                    <Col xs={6}  md={4}>
                        {props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_2_col)}
                    </Col>
                </Row>
                <Row className="mt-27">
                    <Col xs={6} md={4}>
                        {props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_3_col)}
                    </Col>
                </Row>
                <Row className="mt-27">
                    <Col xs={6} md={4} >
                        <p className='text-bold'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_4_col)}</p>
                    </Col>
                </Row>
                <Row className="mb-75">
                    <Col xs={6} md={4} className=''>
                        <span>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_5_col_span_1)} </span>
                        <span className='border-bottom-1-blk'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_5_col_span_2)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} md={2}>
                        <span className='pull-right'>
                        <Button className='btn btn-secondary' onClick={props.onHide}>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_6_cancel_button)}</Button>
                        </span>
                    </Col>
                    <Col xs={3} md={2}>
                        <Button className='btn btn-primary' onClick={props.nextStep}>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep1_row_6_next_button)}</Button>
                    </Col>
                </Row>
            </Grid>
    );
};

const PaymentPlanStep2 = props => {
    const submit = () => {

        const Msg = () => (
            <div>
                <FontAwesomeIcon icon={faCheckCircle} style={{color: "#23b316"}} className="fa-5x"/>
                <h4><span>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_msg_h4)}</span></h4>
                <p className='font-size-16'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_msg_p1)}</p>
                <p className='text-bold font-size-20'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_msg_p2)}</p>
                <p className='font-size-16'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_msg_p3)}</p>
                <p className='font-size-12 opacity-50 '>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_msg_p4)}</p>
            </div>
          )

            toast.info(<Msg />, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "job_success"
            });

            props.onHide();
    };

    return (
        <Grid>
            <Row className="mt-75">
                <Col xs={6} md={4}>
                    <h3>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_1_h3)}</h3>
                </Col>
            </Row>
            <Row className="mt-16">
                <Col xs={6} md={4}>
                    <PaymentInstallments
                        installments={[4,6,12,18]}
                        infoText={props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_2_infoText)}
                    />
                </Col>
            </Row>
            <Row className="mt-27">
                <Col xs={6}  md={4}>
                    <span>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_3_span_1)} </span>
                    <span className='text-bold'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_3_span_2)}</span>
                    <p>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_3_p)}</p>
                </Col>
            </Row>
            <Row className="">
                <Col xs={6}  md={4}>
                    <h4>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_4_h4)}</h4>
                </Col>
            </Row>
            <Row className="show-grid mt-16">
                <Col xs={12} md={8}>
                    <Calendar
                        defaultView="month"
                    />
                </Col>
            </Row>
            <Row className="mt-24">
                <Col xs={6}  md={4}>
                    <span className='text-bold font-size-16'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_6_span_1)} </span>
                    <span className='text-bold font-size-16'>{props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_6_span_2)}</span>
                </Col>
            </Row>
            <Row className="mt-16">
                <Col xs={6} md={4}>
                    <span className='pull-right'>
                        <Button className='btn btn-primary font-size-16' onClick={() => {submit()}}>
                            {props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_7_accept_button)}
                        </Button>
                    </span>
                </Col>
            </Row>
            <Row className='mt-27'>
                <Col xs={6} md={4}>
                    <span className='pull-right'>
                        <Button className='btn btn-secondary font-size-16' onClick={props.onHide}>
                            {props.intl.formatMessage(paymentPlanmsg.PaymentPlanStep2_row_7_cancel_button)}
                        </Button>
                    </span>
                </Col>
            </Row>
        </Grid>
    );
};


const PaymentPlan = (props) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
        <a className='color-dark-gray' onClick={() => setModalShow(true)}>
          {props.text}
        </a>

        <PaymentPlanModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          intl={props.intl}
        />
      </>
    )
}

export default injectIntl(PaymentPlan)