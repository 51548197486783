import {toggleLoading} from "../helpers/index";
import axios from 'axios';
import { toast } from "react-toastify";

// function to download pdf 
export const download = (jobcode, token) => {

    toggleLoading();

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/pdf"
    };

    headers["Authorization"] = "Bearer " + token;
    axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/online/pdf/' + jobcode + '?origtype=1',
        headers: headers,
        responseType: 'blob',
        params: {
            id: jobcode,
        }
    })
    .then(function(res) {
        // create empty a element
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        // create blob object
        let newBlob = new Blob([ res.data ], { type: "application/pdf" });

        // create link
        const url = window.URL.createObjectURL(newBlob);

        a.href = url;
        a.download = "invoice_" + jobcode + '.pdf';
        a.click();

        // open link
        setTimeout(function() {
            window.URL.revokeObjectURL(url);
        }, 100);
    })
    .catch(function(error) {
        console.log(error);
        toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "download_failed"
        });

    })
    .then(function() {
        toggleLoading(false);
    });
};