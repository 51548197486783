import React, {useContext} from 'react';
import {Button, Clearfix} from 'react-bootstrap'
import {injectIntl} from "react-intl";
import {invoiceDownloadBtnmsg} from '../../constants/messages';
import { JobContext } from '../../context/job-context';
import { UserContext } from '../../context/user-context';
import { download } from '../../services/pdf';
import './invoiceDownloadBtn.css'

// Component for invoice download button and its functionalities
const InvoiceDownloadBtn = (props) => {
    const {job} = useContext(JobContext);
    const {getToken} = useContext(UserContext);

    return (
        <>
		{/* loading and content div are needed to be able to show toggleloading component!*/}
		<div id="loading">
        	<div className="content"/>
        </div>
        <Clearfix visibleXsBlock className='mt-24'/>
            { props.invoice.downloadpdf ? (
                <Button onClick={() => {download(job.dpid, getToken())}}>
                    <span className='border-bottom-1-blk'>{props.intl.formatMessage(invoiceDownloadBtnmsg.button)}</span>
                </Button>
            ) : ('') }
        </>
    );
};

export default injectIntl(InvoiceDownloadBtn);
