import React, {createContext, useState} from "react";
import axios from "axios";

/**
 * Create WhiteLabelContext
 */
const WhiteLabelContext = createContext();

const WhiteLabel = (props) => {
    // State variable that contains images object url that can be used straight as an img url. 
    const [logo, setLogo] = useState('')

    // State variable that contains style json.
    const [style, setStyle] = useState('');

    // State variable to help context consumers to know when assets fetch are done.
    const [done, setDone] = useState(false);

    /**
     * Function to get public url's for the public assets and 
     * fetch style and logo from those url's
     * @param {*} token 
     * @param {*} cid 
     * @returns 
     */
    const fetchAssetUrls = (token, cid) => {
             
        return new Promise((resolve, reject) => {
            axios({
                method: "GET",
                url: process.env.REACT_APP_API_URL + "/online/asset",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                },
                params: {
                    "cid": cid
                }
            })
            .then((res) => {
                
                const data = res.data.data
                
                if (data && data.find((item) => {return item.type === 'webinvoicelogo'})) {
                    const webinvoicelogo = 'https://' + data.find((item) => {return item.type === 'webinvoicelogo'}).url;
                    fetchImage(webinvoicelogo);
                }

                if (data && data.find((item) => {return item.type === 'webinvoicestyle'})) {
                    const webinvoicestyle = 'https://' + data.find((item) => {return item.type === 'webinvoicestyle'}).url;
                    fetchStyle(webinvoicestyle);
                }
                
                setDone(true);
                resolve();
            })
            .catch((err) => {
                /**
                 * http status code 404 means, there's no customer assets 
                 * for style and logo so its fully acceptable and, 
                 * not an a error so we can resolve promise in this case.
                 */
                if (err.response && err.response.data.Code == 404) {
                    resolve("no assest found");
                    setDone(true);
                }

                // reject promise
                reject(err);
                setDone(true);
            })
        });
    }

    /**
     * Function to get logo image 
     * @param {*} url 
     */
    const fetchImage = async (url) => {
        const res = await fetch(url);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setLogo(imageObjectURL);
    };

    /**
     * Function to get style json
     * @param {*} url 
     */
    const fetchStyle = async (url) => {
        axios({
            method: "GET",
            url: url
        })
        .then((res) => {
            setStyle(res.data);
        })
    }
 
    return (
        <WhiteLabelContext.Provider value={{ fetchAssetUrls, logo, style, done }}>
            {props.children}
        </WhiteLabelContext.Provider>
    )
}

export {WhiteLabel, WhiteLabelContext}