import React from 'react';
import {injectIntl} from "react-intl";
import {Grid, Row, Col} from 'react-bootstrap'
import {tablemsg} from '../../constants/messages'
import './table.css';

// Component to show invoice basic information
const InvoiceTable = (props) => {
    return (
        <>
            <Grid className='bg-light-gray width100 border-top-1 border-bottom-1'>
                <Row className='padding4'>
                    <Col xs={8} sm={8} md={8}><span>{props.intl.formatMessage(tablemsg.row1title)}</span></Col>
                    <Col xs={4} sm={4} md={4}><span className='text-bold pull-right'>{props.capital} {props.currency}</span></Col>
                </Row>
                <Row className='padding4'>
                    <Col xs={8} sm={8} md={8}><span>{props.intl.formatMessage(tablemsg.row2title)}</span></Col>
                    <Col xs={4} sm={4} md={4}><span className='text-bold pull-right'>{props.invoiceNumber}</span></Col>
                </Row>
                <Row className='padding4'>
                    <Col xs={8} sm={8} md={8}><span>{props.intl.formatMessage(tablemsg.row3title)}</span></Col>
                    <Col xs={4} sm={4} md={4}><span className='text-bold pull-right'>{props.paydate}</span></Col>
                </Row>
            </Grid>
        </>
    );
};


export default injectIntl(InvoiceTable);
