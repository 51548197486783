import { defineMessages } from "react-intl";

const headermsg = defineMessages({
    title: {
		id: 'Att betala',
		defaultMessage: 'To pay:',
        description: 'Title that is shown right under navbar as a header of page'
	},
	ispaid: {
		id: 'Your invoice is paid',
		defaultMessage: 'Your invoice is paid',
		description: ''
	},
    invoiceNumber: {
		id: 'Invoice',
		defaultMessage: 'Invoice',
        description: ''
	},
});


const tablemsg = defineMessages({
    row1title: {
		id: 'Amount, incl. VAT',
		defaultMessage: 'Amount, incl. VAT',
        description: ''
	},
    row2title: {
		id: 'Invoice/OCR',
		defaultMessage: 'Invoice/OCR',
        description: ''
	},
    row3title: {
		id: 'Due date',
		defaultMessage: 'Due date',
        description: ''
	},
});

const paymentoptionsmsg = defineMessages({
    title: {
		id: 'Your payment options',
		defaultMessage: 'Your payment options',
        description: ''
	},
});

const invoicedetailsmsg = defineMessages({
    title: {
		id: 'Invoice specification',
		defaultMessage: 'Invoice specification',
        description: ''
	},
    makePaymentPlan: {
		id: 'Create payment plan',
		defaultMessage: 'Create payment plan',
        description: ''
	},
    movePaydate: {
		id: 'Change due date',
		defaultMessage: 'Change due date',
        description: ''
	},
	invoiceDate: {
		id: 'Date',
		defaultMessage: 'Date',
		description: ''
	},
	invoiceNumber: {
		id: 'Invoice number',
		defaultMessage: 'Invoice number',
		description: ''
	},
	deliveryAddress: {
		id: 'Address',
		defaultMessage: 'Address',
		description: ''
	},
	totalToBePaid: {
		id: 'Totalt amount payable',
		defaultMessage: 'Totalt amount payable',
		description: ''
	},
	vat: {
		id: 'VAT',
		defaultMessage: 'VAT',
		description: ''
	},
	amount: {
		id: 'Amount',
		defaultMessage: 'Amount',
		description: ''
	}
});

const changeDueDatemsg = defineMessages({
	submit_success_p1: {
		id: 'Due date is changed to',
		defaultMessage: 'Due date is changed to',
		description: ''
	},
	submit_success_p2: {
		id: '7 days before, we will send you a new invoice for payment.',
		defaultMessage: '7 days before, we will send you a new invoice for payment.',
		description: ''
	},
	modal_header_h3: {
		id: 'Select new due date',
		defaultMessage: 'Select new due date',
		description: ''
	},
	modal_body_cancel_button: {
		id: 'Cancel',
		defaultMessage: 'Cancel',
		description: ''
	},
	modal_body_select_button: {
		id: 'Choose',
		defaultMessage: 'Choose',
		description: ''
	},
	done: {
		id: "Done!",
		defaultMessage: "Done!",
		description: ""
	},
	invoiceNumber: {
		id: 'Invoice',
		defaultMessage: 'Invoice',
        description: ''
	},
});

const faqmsg = defineMessages({
	modal_header_b: {
		id: 'Frequently asked questions about invoices',
		defaultMessage: 'Frequently asked questions about invoices',
		description: ''
	},
	modal_body_panel_1_heading_title: {
		id: 'Collapsible Group Item #1',
		defaultMessage: 'Collapsible Group Item #1',
		description: ''
	},
	modal_body_panel_1_body: {
		id: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		defaultMessage: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		description: ''
	},
	modal_body_panel_2_heading_title: {
		id: 'Collapsible Group Item #2',
		defaultMessage: 'Collapsible Group Item #2',
		description: ''
	},
	modal_body_panel_2_body: {
		id: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		defaultMessage: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		description: ''
	},
	modal_body_panel_3_heading_title: {
		id: 'Collapsible Group Item #3',
		defaultMessage: 'Collapsible Group Item #3',
		description: ''
	},
	modal_body_panel_3_body: {
		id: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		defaultMessage: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
		description: ''
	}
});

const invoiceDownloadBtnmsg = defineMessages({
	button: {
		id: "Download the invoice",
		defaultMessage: "Download the invoice",
		description: ''
	}
});

const swishPaymentModalmsg = defineMessages({
	modal_body_row_1_col_button: {
		id: "Back",
		defaultMessage: "Back",
		description: ""
	},
	modal_body_row_2_col_h3: {
		id: "To pay:",
		defaultMessage: "To pay:",
		description: ""
	},
	modal_body_row_3_col_li_1: {
		id: "Payee",
		defaultMessage: "Payee",
		description: ""
	},
	modal_body_row_3_col_li_2: {
		id: "Ropo Capital",
		defaultMessage: "Ropo Capital",
		description: ""
	},
	modal_body_row_4_col_1_li_1: {
		id: "Invoice date",
		defaultMessage: "Invoice date",
		description: ""
	},
	modal_body_row_4_col_2_li_1: {
		id: "Invoice number",
		defaultMessage: "Invoice number",
		description: ""
	},
	modal_body_row_4_col_3_li_1: {
		id: "Invoice number",
		defaultMessage: "Invoice number",
		description: ""
	},
	modal_body_row_6_col_label: {
		id: "Cell phone",
		defaultMessage: "Cell phone",
		description: ""
	},
	modal_body_row_8_col_button: {
		id: "Pay with Swish",
		defaultMessage: "Pay with Swish",
		description: ""
	},
	error_ACMT03: {
		id: "The payer is not connected to Swish.",
		defaultMessage: "The payer is not connected to Swish.",
		description: ""
	},
	error_PA02: {
		id: "Amount is missing or not valid.",
		defaultMessage: "Amount is missing or not valid.",
		description: ""
	},
	error_VR01: {
		id: "Payer does not meet age limit.",
		defaultMessage: "Payer does not meet age limit.",
		description: ""
	},
	error_VR02: {
		id: "The payer's alias is not connected to Swish with the specified social security number.",
		defaultMessage: "The payer's alias is not connected to Swish with the specified social security number.",
		description: ""
	},
	error_BE18: {
		id: "The payer's alias is invalid.",
		defaultMessage: "The payer's alias is invalid.",
		description: ""
	},
	error: {
		id: "Something got wrong. Please try again.",
		defaultMessage: "Something got wrong. Please try again.",
		description: ""
	},
	amount_inc_taxes: {
		id: "Amount, including taxes",
		defaultMessage: "Amount, including taxes",
		description: ""
	},
	invoice_ocr: {
		id: "Invoice/OCR",
		defaultMessage: "Invoice/OCR",
		description: ""
	},
	invoice: {
		id: "Invoice",
		defaultMessage: "Invoice",
		description: ""
	},
	due_date: {
		id: "Due date",
		defaultMessage: "Due date",
		description: ""
	},
	paid: {
		id: "Paid",
		defaultMessage: "Paid",
		description: ""
	},
	paid_with_swish: {
		id: "You have paid invoice with Swish in",
		defaultMessage: "You have paid invoice with Swish in",
		description: ""
	},
	waiting_payment: {
		id: "Open your Swish app to complete payment",
		defaultMessage: "Open your Swish app to complete payment",
		description: ""
	},
	close: {
		id: "Close",
		defaultMessage: "Close",
		description: ""
	}
});

const paymentPlanmsg = defineMessages({
	PaymentPlanStep1_row_1_h3: {
		id: "Create payment plan",
		defaultMessage: "Create payment plan",
		description: ""
	},
	PaymentPlanStep1_row_2_col: {
		id: "You can split the payment to a maximum of 3 times.",
		defaultMessage: "You can split the payment to a maximum of 3 times.",
		description: ""
	},
	PaymentPlanStep1_row_3_col: {
		id: "Between each payment there are 4 weeks.",
		defaultMessage: "Between each payment there are 4 weeks.",
		description: ""
	},
	PaymentPlanStep1_row_4_col: {
		id: "Surcharge: 200 kr.",
		defaultMessage: "Surcharge: 200 kr.",
		description: ""
	},
	PaymentPlanStep1_row_5_col_span_1: {
		id: "Questions?",
		defaultMessage: "Questions?",
		description: ""
	},
	PaymentPlanStep1_row_5_col_span_2: {
		id: "Contact Ropo customer service",
		defaultMessage: "Contact Ropo customer service",
		description: ""
	},
	PaymentPlanStep1_row_6_cancel_button: {
		id: "Cancel",
		defaultMessage: "Cancel",
		description: ""
	},
	PaymentPlanStep1_row_6_next_button: {
		id: "Next...",
		defaultMessage: "Next...",
		description: ""
	},
	PaymentPlanStep2_msg_h4: {
		id: "Done!",
		defaultMessage: "Done!",
		description: ""
	},
	PaymentPlanStep2_msg_p1: {
		id: "Due date is changed to",
		defaultMessage: "Due date is changed to",
		description: ""
	},
	PaymentPlanStep2_msg_p2: {
		id: "28 october 2022",
		defaultMessage: "28 october 2022",
		description: ""
	},
	PaymentPlanStep2_msg_p3: {
		id: "7 days before, we will send you a new invoice for payment.",
		defaultMessage: "7 days before, we will send you a new invoice for payment.",
		description: ""
	},
	PaymentPlanStep2_msg_p4: {
		id: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec metus massa.",
		defaultMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec metus massa.",
		description: ""
	},
	PaymentPlanStep2_row_1_h3: {
		id: "Select number of payments",
		defaultMessage: "Select number of payments",
		description: ""
	},
	PaymentPlanStep2_row_2_infoText: {
		id: "times",
		defaultMessage: "times",
		description: ""
	},
	PaymentPlanStep2_row_3_span_1: {
		id: "Monthly fee:",
		defaultMessage: "Monthly fee:",
		description: ""
	},
	PaymentPlanStep2_row_3_span_2: {
		id: "377,90 kr/month",
		defaultMessage: "377,90 kr/month",
		description: ""
	},
	PaymentPlanStep2_row_3_p: {
		id: "The additional fee of SEK 200 is paid on the first payment. Any reminder fees will be added.",
		defaultMessage: "The additional fee of SEK 200 is paid on the first payment. Any reminder fees will be added.",
		description: ""
	},
	PaymentPlanStep2_row_4_h4: {
		id: "Select first payment day",
		defaultMessage: "Select first payment day",
		description: ""
	},
	PaymentPlanStep2_row_6_span_1: {
		id: "Surcharge for payment plan:",
		defaultMessage: "Surcharge for payment plan:",
		description: ""
	},
	PaymentPlanStep2_row_6_span_2: {
		id: "200 kr",
		defaultMessage: "200 kr",
		description: ""
	},
	PaymentPlanStep2_row_7_accept_button: {
		id: "Create payment plan",
		defaultMessage: "Create payment plan",
		description: ""
	},
	PaymentPlanStep2_row_7_cancel_button: {
		id: "Cancel",
		defaultMessage: "Cancel",
		description: ""
	}
});

const errorPagemsg = defineMessages({
    errorPage_invoice_load_error: {
        id: 'We are unable to load the information at this time, please try again later.',
        defaultMessage: 'We are unable to load the information at this time, please try again later.',
        description: ''
    },
});

export {
	headermsg,
	tablemsg,
	paymentoptionsmsg,
	invoicedetailsmsg,
	changeDueDatemsg,
	faqmsg,
	invoiceDownloadBtnmsg,
	swishPaymentModalmsg,
	paymentPlanmsg,
    errorPagemsg
};