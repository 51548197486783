import React, {createContext, useState} from "react";
import axios from "axios";

const UserContext = createContext();

const User = (props) => {
    const [token, setToken] = useState('');
    
    /**
     * Function to authenticate user by given uuid. 
     * @param {string} uuid 
     * @returns {Promise}
     */
    const authenticate = (uuid) => {       
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: process.env.REACT_APP_API_URL + "/onlinetoken/" + uuid,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
            .then((res) => {
                setToken(res.data.token);
                resolve(res.data.token);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }

    /**
     * Function to get token.
     * If token is not set returns empty string
     * @returns {string} token
     */
    const getToken = () => {
        return token;
    }

    return (
        <UserContext.Provider value={{ authenticate, getToken, token }}>
            {props.children}
        </UserContext.Provider>
    )

}

export {User, UserContext}