import React, {useState} from "react";

/**
 * Component to hold NotifyModal state
 */
export default () => {
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalHeader, setModalHeader] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalStyling, setModalStyling] = useState({});
    const [modalCloseBtnTxt, setModalCloseBtnTxt] = useState('Close');


    const handleModal = ({content = false, message = false, header = false, styling = false, closeButtonTxt = false}) => {
        setModal(!modal);

        if (content) {
            console.log('content ' + content);
            setModalContent(content);
        }

        if (message) {
            setModalMessage(message);
        }

        if (header) {
            console.log('header ' + header);
            setModalHeader(header);
        }

        if (styling) {
            console.log('styling ' + styling);
            setModalStyling(styling);
        }

        if (closeButtonTxt) {
            console.log('closeButtonTxt ' + closeButtonTxt);
            setModalCloseBtnTxt(closeButtonTxt);
        }
  };

  return { modal, handleModal, modalContent, modalHeader, modalMessage, modalStyling, modalCloseBtnTxt };
};