import Moment from 'moment';

let loadingAnimation = false;

export function toggleLoading(on, content) {
	const elem = document.getElementById("loading");

	if(typeof elem !== "undefined" && elem !== null) {
		elem.querySelector('.content').innerHTML = "Ladataan...";

		if (typeof on === 'undefined' || on === true) {
			loadingAnimation = true;
			elem.classList.add('visible');

			if (typeof content !== 'undefined') {
				elem.querySelector('.content').innerHTML = content;
			}
		}
		else {
			loadingAnimation = false;
			elem.classList.remove('visible');
		}
	}
}

export function changeLoadingText(text) {
	const elem = document.getElementById("loading");
	elem.querySelector('.content').innerHTML = text;
}

export function parseDate(str, format) {
	let date;

	const locale = localStorage.getItem('locale');

	if (typeof format !== "undefined") {
		date = Moment(str).format(format).toString();
	}
	else if(locale !== null && locale === 'en') {
		date = Moment(str).format("YYYY/MM//DD").toString();
	}
	else {
		date = Moment(str).format("DD.MM.YYYY").toString();
	}

	return date;
}

export function getIsoDateTime() {
	return Moment().toISOString();
}

export function isLoading() {
	return loadingAnimation;
}

// Validoidaan lomakkeen syöttökentät hyödyntäen HTML5 API
export function validate(formEl) {
	const formLength = formEl.length;

	// Tarkistaa, että validoidaanko lomake ilman lähetystä
	if (formEl.checkValidity() === false) {
		// Käydään läpi kaikki lomakkeen elementit
		for (let i = 0; i < formLength; i++) {
			const elem = formEl[ i ];
			// Poistetaan olemassaoleva virheluokka
			elem.classList.remove("error");
			const errorLabel = elem.parentNode.querySelector(".invalid-feedback");

			if (errorLabel && elem.nodeName.toLowerCase() !== "button") {
				if (!elem.validity.valid) {
					// Napataan attribuutista "validationmessage" virheilmoitus ja esitetään se
					errorLabel.textContent = elem.getAttribute("validationmessage");
					// Lisätään virheluokka
					elem.classList.add("error");
				}
				else {
					// Ei virhettä
					errorLabel.textContent = "";
				}
			}
		}

		return false;
	}
	else {
		for (let i = 0; i < formLength; i++) {
			const elem = formEl[ i ];
			const errorLabel = elem.parentNode.querySelector(".invalid-feedback");
			if (errorLabel && elem.nodeName.toLowerCase() !== "button") {
				errorLabel.textContent = "";
			}
		}

		return true;
	}
}

/**
 * Check if a cookie with given name exists.
 *
 * @param name
 * @return {boolean}
 */
export function hasCookie(name) {
	// getCookie only returns false if the cookie doesnt exist
	return getCookie(name) !== false;
}

/**
 * Get a single cookie
 * @param name
 * @return mixed
 */
export function getCookie(name) {
	let response = false;
	const cookieArr = document.cookie.split(";");

	// Loop through the array elements
	for(let i = 0; i < cookieArr.length; i++) {
		const cookiePair = cookieArr[i].split("=");

		if (name === cookiePair[0].trim()) {
			// Decode the cookie value and return
			response = decodeURIComponent(cookiePair[1]);
		}
	}

	return response;
}

/**
 * Maps provided locale code to http headers compliant BCP 47 (IETF) language code
 *
 * @param locale
 * @returns {string}
 */
export function mapLocaleToHeaders(locale) {
	switch(locale) {
		case 'se':
			locale = 'sv-SE';
			break;
		case 'en':
			locale = 'en-GB';
			break;
		case 'no':
			locale = 'nb-NO';
			break;
		case 'fi':
		default:
			locale = 'fi-FI';
			break;
	}

	return locale;
}

/**
 * Maps provided locale code to http headers compliant BCP 47 (IETF) language code
 *
 * @param locale
 * @returns {string}
 */
export function mapLocaleToBCP47(locale) {
	switch(locale) {
		case 'se':
			locale = 'sv_SE';
			break;
		case 'en':
			locale = 'en_GB';
			break;
		case 'no':
			locale = 'nb_NO';
			break;
		case 'fi':
		default:
			locale = 'fi_FI';
			break;
	}

	return locale;
}

export function formatMoney(value, locale, currency) {
	let response = value;

    if (locale.length === 2) {
        locale = mapLocaleToHeaders(locale);
    }

    // Currency has to be three letter code, otherwise use fallback (usually env variable value)
    if (typeof currency !== 'undefined' && currency && currency.length === 3) {
		response = value.toLocaleString(locale, {
			style: 'currency',
			currency: currency.toUpperCase()
		});
    }

    return response;
}

export function parseLinkVariable(locale, variable) {
	if(variable && variable !== '') {
		// Split by double ||
		let links = variable.split('||');

		if (links.length > 0) {
			for (let i = 0; i < links.length; i++) {
				const link = links[i];

				// Assume link is {LOCALE}:https://www.example.com/...
				let parts = link.split(':');
				const linkLocale = parts[0];

				if (linkLocale.toUpperCase() === locale.toUpperCase()) {
					parts.shift();
					return parts.join(':');
				}
			}
		}
	}
}