import React, {useState} from 'react';
import {injectIntl} from "react-intl";
import {Collapse, Button, Table, Grid, Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {invoicedetailsmsg} from '../../constants/messages'
import ChangeDueDate from '../ChangeDueDate/changeDueDate';
import PaymentPlan from '../PaymentPlan/paymentPlan';

import './invoiceDetails.css'

// Component for all invoice details and its functionalities.
const InvoiceDetails = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setOpen(!open)} className='btn-block text-aling-start bg-light-gray font-size-12 text-bold'>
                <span className='pull-left'>{props.intl.formatMessage(invoicedetailsmsg.title)}</span>
                <span className='pull-right'><FontAwesomeIcon icon={open ? faAngleUp : faAngleDown}/></span>
            </Button>
            <Collapse in={open}>
                <Grid className='collapse-content'>
                    <Row className='border-top-4 padding-top-8'>
                        <Col xs={8} sm={8} md={8}><span className='text-bold'>{props.intl.formatMessage(invoicedetailsmsg.invoiceDate)}:</span></Col>
                        <Col xs={4} sm={4} md={4}><span className='pull-right'>{props.invoice.billdate}</span></Col>
                    </Row>

                    <Row>
                        <Col xs={8} sm={8} md={8}><span className='text-bold'>{props.intl.formatMessage(invoicedetailsmsg.invoiceNumber)}:</span></Col>
                        <Col xs={4} sm={4} md={4}><span className='pull-right'>{props.invoice.billnum}</span></Col>
                    </Row>

                    <Row>
                        <Col xs={8} sm={8} md={8}><span className='text-bold'>{props.intl.formatMessage(invoicedetailsmsg.deliveryAddress)}:</span></Col>
                    </Row>
                    <Row className='border-bottom-1 padding-bottom-8'>
                        <Col xs={8} sm={8} md={8}><span>{props.invoice.name}, {props.invoice.address[0].address}, {props.invoice.address[0].postcode} {props.invoice.address[0].city}</span></Col>
                    </Row>
                    {props.invoice.payrows.map((payrow) => {
                        return (
                            <Row className='padding-top-8'>
                                <Col xs={8} sm={8} md={8}><span>{payrow.desc}</span></Col>
                                <Col xs={4} sm={4} md={4}><span className='pull-right'>{payrow.totalamount} {props.invoice.currency}</span></Col>
                            </Row>
                        );
                    })}
                    <Row className='border-top-1 mt-8 padding-top-8'>
                        <Col xs={8} sm={8} md={8}><span className='text-bold'>{props.intl.formatMessage(invoicedetailsmsg.amount)}</span></Col>
                        <Col xs={4} sm={4} md={4}><span className='pull-right text-bold'>{props.invoice.capital} {props.invoice.currency}</span></Col>
                    </Row>
                    <Row className='padding-top-8'>
                        <Col xs={8} sm={8} md={8}><span>{props.intl.formatMessage(invoicedetailsmsg.vat)}</span></Col>
                        <Col xs={4} sm={4} md={4}><span className='pull-right'>{props.invoice.currency}</span></Col>
                    </Row>
                    <Row className='padding-top-8 mb-8'>
                        <Col xs={8} sm={8} md={8}><span className='text-bold'>{props.intl.formatMessage(invoicedetailsmsg.totalToBePaid)}</span></Col>
                        <Col xs={4} sm={4} md={4}><span className='pull-right text-bold'>{props.invoice.capital} {props.invoice.currency}</span></Col>
                    </Row>
                    <Row className='border-top-1 padding-top-16 padding-bottom-16'>
                        { props.invoice.modpaydate ? (
                            <Col xs={6}  md={6} className='text-center'>
                                <span className='font-size-12 border-bottom-1-blk color-dark-gray'>
                                    <ChangeDueDate
                                        text={props.intl.formatMessage(invoicedetailsmsg.movePaydate)}
                                        invoice={props.invoice}
                                    />
                                </span>
                            </Col>
                        ) : ('') }
                    </Row>
                </Grid>
            </Collapse>
        </>
    )
}

export default injectIntl(InvoiceDetails);
