import React from "react";
import useModal from "./useModal";
import NotifyModal from "./NotifyModal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

/**
 * Component that provides the NotifyModal for Provider childrens
 *
 * @param {children}
 * @returns
 */
const ModalProvider = ({ children }) => {
    const { modal, handleModal, modalContent, modalHeader, modalMessage, modalStyling, modalCloseBtnTxt } = useModal();
    return (
        <Provider value={{ modal, handleModal, modalContent, modalHeader, modalMessage, modalStyling, modalCloseBtnTxt }}>
            <NotifyModal />
            {children}
        </Provider>
  );
};

export { ModalContext, ModalProvider };