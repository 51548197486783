import React, {useState} from "react";
import { Navbar } from 'react-bootstrap'
import images  from '../../constants/images';
import { injectIntl } from "react-intl";
import './navbar.css';
import Faq from "../Faq/faq";

// Navbar component
const WINavbar = (props) => {
	const [logoLoaded, setLogoLoaded] = useState(false);
    const defaultBackground = "var(--color-red)";

    return (
        <Navbar style={{background: props.style ? props.style['background-color'] : defaultBackground}}>
            <Navbar.Header>
                <Navbar.Brand>
                    {props.logo && 
                        <img
                        className={logoLoaded ? '' : 'hidden'}
                        onLoad={() => setLogoLoaded(true)} 
                        src={props.logo} 
                        alt="Brand"/>
                    }
                </Navbar.Brand>
            </Navbar.Header>
        </Navbar>
    )
}

export default injectIntl(WINavbar);