import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Grid, Row, Col } from "react-bootstrap";
import {injectIntl} from "react-intl";
import {changeDueDatemsg} from '../../constants/messages';
import { JobContext } from "../../context/job-context";
import { UserContext } from "../../context/user-context";
import { ModalContext } from "../NotifyModal/modalContext";
import Calendar from "react-calendar";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import "react-calendar/dist/Calendar.css";
import "./changeDueDate.css";
import images from "../../constants/images";

const ChangeDueDateModal = (props) => {
	/**
	 * Minimum days that the due date can be forwarded.
	 * This because dates below this limit cant be charged from recipient by law.
	 * This variable is use to check new due date selection limits lower limit.
	 */
	const minimumDaysForward = 14;

	/**
	 * Maximum days that the due date can be forwarded.
	 * data can contain modpaydatemax variable that tells the upper limit in weeks
	 * so it must be multiplied by amount of days in week to get it in appropriate form.
	 * If this variable is missing from data or it is javascript falsy minumunDaysForward are used also upper limit.
	 * This variable is use to check new due date selection upper limit.
	 */
	const maximumDaysForward = props.invoice.modpaydatemax
	? props.invoice.modpaydatemax * 7
	: minimumDaysForward;

	/**
	 * Original due date is used as lower limit of calendar range visualisation.
	 */
	const originalDueDate = new Date(props.invoice.paydate);

	/**
	 * State variable for new due date that are selected and send to backend.
	 * Variable is initialized to minimum acceptable value and user can change
	 * this value in calendar component (minimum and maximum acceptable dates are introduced previously).
	 */
	const [newDueDate, setNewDueDate] = useState(
	Moment(originalDueDate).add(minimumDaysForward, "days").toDate()
	);

	/**
	 * Minimum date for Calendar components range.
	 */
	const minDate = new Date(Moment(originalDueDate).add(minimumDaysForward, "days").format("YYYY-MM-DD"))

	/**
	 * Maximum date for Calendar components range.
	 */
	const maxDate = new Date(Moment(originalDueDate).add(maximumDaysForward, "days").format("YYYY-MM-DD"));

	/**
	 * Get submitNewDuedate function from JobContext.
	 * The submitNewDuedate function is used to update due date into backend.
	 */
	const { submitNewDuedate } = useContext(JobContext);

	/**
	 * Get getToken function from JobContext.
	 * The getToken function is used to get authorization token to authorize backend request.
	 */
	const { getToken } = useContext(UserContext);

	/**
	 * Get handleModal funvtion from ModalContext
	 * The handleModal is used to show modal.
	 */
	const { handleModal } = useContext(ModalContext);

	const styling = '';
	const defaultBackground = "var(--color-red)";

	/**
	 * Function to submit new due date into backend
	 */
	const submit = () => {

		const newDueDateString = Moment(newDueDate).format("YYYY-MM-DD").toString();

		// actual submition using submitNewDuedate
		submitNewDuedate(
			props.invoice.dpid,
			newDueDateString,
			getToken()
		)
		.then((res) => {
			handleModal({
				message: <SuccessMessageMessage intl={props.intl} newDueDateString={newDueDateString} />,
				header: <SuccessMessageHeader intl={props.intl} invoice={props.invoice} />,
				closeButtonTxt: props.intl.formatMessage(changeDueDatemsg.done)
			});
			props.onHide();
		})
		.catch((err) => {
			toast.error(err.toString(), {
				position: toast.POSITION.TOP_CENTER,
				toastId: "newduedate_failed",
			});
		});
	};

	return (
		<Modal
			{...props}
			size="lg"
			dialogClassName="full_screen duedate-dialog"
			animation={false}
    	>
			<Modal.Header style={{background: styling.background ? styling.background : defaultBackground}}>
				<Grid>
					<Row>
						<Col md={12}>
							<img className="navbar-brand" src={images.guardhome} alt="company"/>
							<a className={"close"} onClick={props.onHide}><FontAwesomeIcon icon={ [ 'fa', 'times-circle' ] }/></a>
						</Col>
					</Row>
				</Grid>
			</Modal.Header>
			<Modal.Body>
				<Grid>
					<Row className="mt-75">
						<Col xs={12} md={9} lg={6}>
							<div className="duedate-calendar__header">
								<h3>{props.intl.formatMessage(changeDueDatemsg.modal_header_h3)}</h3>
							</div>
						</Col>
					</Row>
					<Row className="mt-16">
						<Col xs={12} md={9} lg={6} className="padding-top-34">
							<Calendar
								className="padding4"
								minDate={minDate}
								value={newDueDate}
								maxDate={maxDate}
								onChange={(selectedDate) =>
									setNewDueDate(selectedDate)
								}
								formatShortWeekday={(locale, date) =>
									["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
								}
								tileClassName={({activeStartDate, date, view}) => {
									if ( new Date(Moment(originalDueDate).format("YYYY-MM-DD")).getTime() == new Date(Moment(date).format("YYYY-MM-DD")).getTime()) {
										return 'react-calendar__tile--originalDuedate';
									}
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={9} lg={6} className="padding-bottom-34">
							<div className="duedate-calendar__footer pull-left">
								<Button className="pull-right btn btn-primary" onClick={() => {submit();}}>
									{props.intl.formatMessage(changeDueDatemsg.modal_body_select_button)}
								</Button>
								<Button className="pull-right btn btn-secondary" onClick={props.onHide}>
									{props.intl.formatMessage(changeDueDatemsg.modal_body_cancel_button)}
								</Button>
							</div>
						</Col>
					</Row>
				</Grid>
			</Modal.Body>
    	</Modal>
  );
};

// Success message Content
const SuccessMessageMessage = (props) => {
	return (
		<div>
			<h4>
				<span>Klart!</span>
			</h4>
			<p className="font-size-16">{props.intl.formatMessage(changeDueDatemsg.submit_success_p1)}</p>
			<p className="text-bold font-size-20">{props.newDueDateString}</p>
			<p className="font-size-16">
				{props.intl.formatMessage(changeDueDatemsg.submit_success_p2)}
			</p>
		</div>
	)
};

const SuccessMessageHeader = (props) => {
	return (

		<div>
			<h3>{props.intl.formatMessage(changeDueDatemsg.invoiceNumber) + ' ' + props.invoice.billnum}</h3> {/*Faktura 284931*/}
		</div>
	)
};

const ChangeDueDate = (props) => {
  	const [modalShow, setModalShow] = useState(false);

	return (
    	<>
      		<a className="color-dark-gray" onClick={() => setModalShow(true)}>
        		{props.text}
      		</a>

      		<ChangeDueDateModal
        		show={modalShow}
				onHide={() => setModalShow(false)}
        		invoice={props.invoice}
				intl={props.intl}
      		/>
    	</>
  );
};

export default injectIntl(ChangeDueDate);
