import React, { useEffect, useContext, useState } from "react";
import { IntlProvider, addLocaleData } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import {Grid, Row, Col} from 'react-bootstrap'

// css
import './css/bootstrap.3.3.7.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/style.css';

import {UserContext} from "./context/user-context";
import {JobContext} from "./context/job-context";
import { WhiteLabelContext } from "./context/whiteLabel-context";


// components
import Navbar from './components/Navbar/navbar';
import Header from './components/Header/header';
import Table from './components/Table/table';
import Swishpayment from "./components/PaymentOptions/swishpayment.js";
import PaymentOptionsTitle from "./components/PaymentOptions/paymentOptionsTitle";
import InvoiceDetails from "./components/InvoiceDetails/invoiceDetails";
import InvoiceDownloadBtn from "./components/InvoiceDownloadBtn/invoiceDownloadBtn";
import Footer from './components/Footer/footer';
import ErrorPage from "./components/ErrorPage/errorPage";

// translations .dsaa
import fi from 'react-intl/locale-data/fi';
import en from 'react-intl/locale-data/en';
import se from 'react-intl/locale-data/se';
import no from 'react-intl/locale-data/no';

import locales_fi from './locales/fi.json';
import locales_en from './locales/en.json';
import locales_se from './locales/se.json';
import locales_no from './locales/no.json';

addLocaleData([...fi, ...en, ...se, ...no]);

let language = (navigator.languages && navigator.languages[0]) || navigator.language;

// Sallitut kielet
const ALLOWED_LANGS = ['fi','en','se','no'];

// Sallitut kielet stringinä routeria varten
const ALLOWED_LANGS_STR = ALLOWED_LANGS.join('|');

// Käännökset
const messages = {
	fi: locales_fi,
	en: locales_en,
	se: locales_se,
    no: locales_no
};

const Invoice = () => {
    const { authenticate } = useContext(UserContext);
    const { fetchJob, job } = useContext(JobContext);
    const { fetchAssetUrls, logo, style, done } = useContext(WhiteLabelContext);
    const [state, setState] = useState({
        loading: true,
        response: null,
        error: null
    });
    
    // function to setlanguage for IntlProvider usage!
    const setLanguage = () => {

        // language is defined as a global variable and it's exist in global context of application.
        // Every time the main component of the appliction is rendered it must be empty.
        language = '';

        // If locale can be found from localStorage use it as a language.
        if (localStorage.getItem('locale') !== null && ALLOWED_LANGS.includes(localStorage.getItem('locale'))) {
            language = localStorage.getItem('locale');
        }

        // If localstorage did not include the locale value try get default from top level domain (TLD).
        if (!ALLOWED_LANGS.includes(language)) {
            language = new URL(window.location.href).hostname.split('.').reverse()[0];
            localStorage.setItem('locale', language);
        }

        // If previous tries did not get determined locale, use browsers defaults.
        if (!ALLOWED_LANGS.includes(language)) {
            // Navigator.language returns 'en-gb' 'fi-en' etc.
            // Because this app has no full support for i18n/l10n functionalities, use only the first 'country' part of array split by '-'.
            language = (navigator.language || navigator.userLanguage).split('-')[0];
            localStorage.setItem('locale', language);
        }

        // If previous tries did not get determined locale, fallback languege to the 'en' by default.
        if (!ALLOWED_LANGS.includes(language)) {
            language = 'en'
            localStorage.setItem('locale', language);
        }
    }

    useEffect(() => {
        const uuid = new URL(window.location.href).hash.split('/').reverse()[0]
        
        if (!uuid) {
            return;
        }

        let token;

        authenticate(uuid)
        .then((res) => {
            token = res;
            setState({
                loading: true,
                error: null
            })
            return fetchJob(null, res);
        })
        .then((res) => {            
            return res.cid;
        })
        .then((cid) => {
            fetchAssetUrls(token, cid);
        })
        .catch((err) => {
            console.error("Failed to initialize", err);
            setState({
                loading: false,
                error: true
            })
            
    
        });

        setLanguage();
    },[]);

    return (
        <>
        <ToastContainer autoClose={6000} pauseOnFocusLoss={false}/>
        <IntlProvider locale={language} messages={messages[language]}>
            {(job && done) ? (
                <>
                    <Navbar logo={logo} style={style.header}/>
                        <Grid className="mb-175">
                            <Row className="show-grid">
                                <Col xs={12} md={9} lg={6}>
                                    <Header invoiceNumber={job.billnum} openAmount={job.openamount}/>
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={9} lg={6}>
                                    <Table invoiceNumber={job.billnum} paydate={job.paydate} capital={job.openamount} currency={job.currency}/>
                                </Col>
                            </Row>
                            <Row className="show-grid mt-24">
                                <Col xs={12} md={9} lg={6}>
                                    <PaymentOptionsTitle/>
                                </Col>
                            </Row>
                            { ![63,101,102,112,122,156,302].includes(job.statuscode) ? (
                                <Row className="show-grid mt-8">
                                    <Col xs={12} md={9} lg={6}>
                                        <Swishpayment invoice={job}/>
                                    </Col>
                                </Row>
                            ) : ('') }
                            <Row className="show-grid mt-8">
                                <Col xs={12} md={9} lg={6}>
                                    <InvoiceDetails invoice={job}/>
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={9} lg={6}>
                                    <InvoiceDownloadBtn invoice={job}/>
                                </Col>
                            </Row>
                        </Grid>
                    <Footer logo={logo} style={style.footer}/>
                </>
            ):(
                <>
                    <Navbar />
                    <ErrorPage showError = {state.error} showLoader = {state.loading}/>
                    <Footer />
                </>
            )}

        </IntlProvider>
        </>
    )
}

export default Invoice