import React, {useContext} from 'react';
import ReactDOM from 'react-dom';

import {User} from "./context/user-context";
import {Job} from "./context/job-context";
import {WhiteLabel} from "./context/whiteLabel-context";
import { ModalProvider } from './components/NotifyModal/modalContext';

// Sentry
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

// JS
import Invoice from './invoice';


// Ikonipaketti
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faHome,
    faSpinner,
    faFileInvoice,
    faCheckCircle,
    faTimesCircle,
    faChevronDown,
    faEnvelope,
    faPowerOff,
    faSearch,
    faHandHoldingUsd,
    faEllipsisV,
    faEuroSign,
    faPencilAlt,
    faCalendarCheck,
    faCalendarAlt,
    faCalendar,
    faDownload,
    faBars,
    faInfoCircle,
    faFrown,
    faFileSignature,
    faArrowDown,
    faArrowUp,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faHome,
    faFileInvoice,
    faSpinner,
    faCheckCircle,
    faTimesCircle,
    faChevronDown,
    faEnvelope,
    faPowerOff,
    faSearch,
    faHandHoldingUsd,
    faEllipsisV,
    faEuroSign,
    faPencilAlt,
    faCalendarCheck,
    faCalendarAlt,
    faCalendar,
    faDownload,
    faBars,
    faInfoCircle,
    faFrown,
    faFileSignature,
    faArrowDown,
    faArrowUp,
    faQuestionCircle
    );

if (typeof process.env.REACT_APP_SENTRY_DNS !== 'undefined') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        environment: typeof process.env.REACT_APP_ENVIRONMENT_TAG !== 'undefined' ? process.env.REACT_APP_ENVIRONMENT_TAG : 'development',
        tracesSampleRate: 1.0,
        allowUrls: [process.env.REACT_APP_URL],
        release: typeof process.env.REACT_APP_RELEASE_NUMBER !== 'undefined' ? process.env.REACT_APP_RELEASE_NUMBER : '0'
    });
}

ReactDOM.render(
    <ModalProvider>
        <User>
            <Job>
                <WhiteLabel>
                    <Invoice/>
                </WhiteLabel>
            </Job>
        </User>
    </ModalProvider>
    , document.getElementById('root'));
