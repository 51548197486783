import React, {useContext} from "react";
import { ModalContext } from "./modalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Col, Grid, Modal, Row } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import './NotifyModal.css';
import images from "../../constants/images";

/**
 * The view component that defines layout of fullscreen modal for notifications.
 * This component uses ModalContext that holds component modifiers as a modalContent, modalHeader and etc.
 * Content and Header of notification have to be given at handleModal function or otherwise its going
 * to show plain modal without content.
 *
 * @component
 * @returns {Modal}
 */
const NotifyModal = () => {
    const { modalCloseBtnTxt, modalStyling, modalHeader, modalMessage, modalContent, handleModal, modal } = useContext(ModalContext);
    const defaultBackground = "var(--color-red)";
    return (
        <Modal
        show={modal}
        size="lg"
        dialogClassName="full_screen notify_modal"
        animation={false}
        >
        <Modal.Header style={{background: modalStyling.background ? modalStyling.background : defaultBackground}}>
            <Grid>
                <Row>
                    <Col xs={12} md={9} lg={6}>
                        <img className="navbar-brand" src={images.guardhome} alt="company"/>
                        <a className={"close"} onClick={() => handleModal()}>
                            <FontAwesomeIcon icon={ [ 'fa', 'times-circle' ] }/>
                        </a>
                    </Col>
                </Row>
            </Grid>

        </Modal.Header>
        <Modal.Body>
            <Grid>
                <Row className="mt-27">
                    <Col xs={12} md={9} lg={6}>
                        {modalHeader}
                    </Col>
                </Row>
                <Row className="mt-27">
                    <Col xs={3} md={2} lg={1}>
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="fa-5x mb-75 text-success"
                        />
                    </Col>
                    <Col xs={9} md={7} lg={4}>
                        {modalMessage}
                    </Col>
                </Row>
                <Row className="mt-27">
                    <Col xs={12} md={9} lg={6}>
                        {modalContent}
                    </Col>
                </Row>
            </Grid>
        </Modal.Body>
        <Modal.Footer>
            <Grid>
                <Row>
                    <Col xs={12} md={9} lg={6}>
                        <button className="btn btn-success btn-lg btn-block" onClick={() => handleModal({})}>
                            {modalCloseBtnTxt}
                        </button>
                    </Col>
                </Row>
            </Grid>
        </Modal.Footer>
    </Modal>
    )
}

export default NotifyModal