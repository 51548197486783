import React from 'react';
import {injectIntl} from "react-intl";
import { errorPagemsg } from "../../constants/messages";
import './errorPage.css';


const ErrorPage = (props) => {
    
    const {
        showLoader = false,
        showError = false,
    } = props;
    
    if (showLoader) {
        return (
            <div class="centered">
               <div className="loader"/>
            </div>
        )
    }
    if (showError) {
        return(
            <div class="centered">
                <h3>{props.intl.formatMessage(errorPagemsg.errorPage_invoice_load_error)}</h3>
            </div>
        )
    }
    return props.children;
};

export default injectIntl(ErrorPage);