import guardhome from '../assets/GuardHome.png';
import swishicon from '../assets/swish-icon.png';
import swishiconlg from '../assets/swish-icon-lg.png';
import flagsweden from '../assets/flag-sweden.png';
import iconcheck from '../assets/icon-check.png';
import flagnorway from '../assets/flag-norway.png';
import flagfinland from '../assets/flag-finland.png';
import flagiceland from '../assets/flag-iceland.png';
import flagdenmark from '../assets/flag-denmark.png';

export default {
    guardhome,
    swishicon,
    swishiconlg,
    flagsweden,
    iconcheck,
    flagnorway,
    flagfinland,
    flagiceland,
    flagdenmark,
}